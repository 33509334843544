* {
    margin: 0;
    padding: 0;
}
@tailwind base;
@tailwind components;
@tailwind utilities;

a {
    text-decoration: none;
}
span {
    padding: 0;
    margin: 0;
}
.text-red {
    color: red;
}
.text-justify {
    text-align: justify;
}

/* [type='text']:focus,
[type='email']:focus,
[type='url']:focus,
[type='password']:focus,
[type='number']:focus,
[type='date']:focus,
[type='datetime-local']:focus,
[type='month']:focus,
[type='search']:focus,
[type='tel']:focus,
[type='time']:focus,
[type='week']:focus,
[multiple]:focus,
textarea:focus,
select:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
    --tw-ring-inset: var(--tw-empty, /*!*/ /*!*/
/* --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: transparent !important;
    --tw-ring-offset-shadow: transparent !important;
    --tw-ring-shadow: transparent !important;
    box-shadow: transparent !important;
    border-color: transparent !important; */
/* } */
